<template>
    <div class="contact_info_box">
        <div style="padding-bottom:16px;height: 390px;">
            <p class="info">
                <span>销售订单编号</span>
                {{ order.orderNo ? order.orderNo : '- -' }}
            </p>
            <p class="info">
                <span>客户名称</span>
                {{ order.companyName ? order.companyName : '- -' }}
            </p>
            <p class="info">
                <span>渠道名称</span>
                {{ order.adminName ? order.adminName : '- -' }}
            </p>
            <p class="info">
                <span>当前交付状态</span>
                {{
                    order.status == 1
                        ? '未交付'
                        : order.status == 2
                        ? '部分交付'
                        : order.status == 3
                        ? '已交付'
                        : '- -'
                }}
            </p>
            <p class="info">
                <span>渠道名称 </span>
                <el-select
                    v-model="adminId"
                    style="width: 240px;height: 50px;"
                    filterable
                    clearable
                    placeholder="请选择"
                >
                    <el-option v-for="item in userName" :label="item.nickName" :value="item.id">
                    </el-option>
                </el-select>
                <br/>
                <span>boss订单额度 </span>
                <el-input
                    v-model="bossInputAmount"
                    style="width: 240px;height: 50px;"
                    placeholder="请输入boss订单额度"
                ></el-input>
                <br/>
                <span>应收金额 </span>
                <el-input
                    v-model="receivableAmount"
                    style="width: 240px;height: 50px;"
                    placeholder="请输入金额"
                ></el-input>
                <br/>
                <span>实收金额 </span>
                <el-input
                    v-model="netReceiptsAmount"
                    style="width: 240px;height: 50px;"
                    placeholder="请输入金额"
                ></el-input>
                <br/>
                <span>实收日期 </span>
                <el-date-picker
                    v-model="netReceiptsTime"
                    style="width: 240px;height: 50px;"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="date"
                    placeholder="请选择实收日期"
                >
                </el-date-picker>
            
                
            </p>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round>保 存</el-button>
        </div>
    </div>
</template>

<script>
import { orderUpdate } from '@/api/order/order.js';
import { roleList } from '@/api/customer/customer.js';
export default {
    data() {
        return {
            order: {},
            userName: [],
            status: '',
            receivableAmount:'',
            netReceiptsAmount:'',
            netReceiptsTime:'',
            adminId: '',
            bossInputAmount:"",
            
        };
    },
    methods: {
        getData(row) {
            console.log(row);
            this.order = row;
            this.receivableAmount = row.receivableAmount;
            this.netReceiptsAmount = row.netReceiptsAmount;
            this.netReceiptsTime = row.netReceiptsTime;
            this.bossInputAmount = row.bossInputAmount;
            this.adminId = row.adminId;
            this.getUserList();
            
        },
        onClose() {
            this.$emit('close');
            this.status = '';
        },
        // 获取部门成员
        getUserList() {
            let data = {
                param: {
                    roleType: 20,
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.order.id,
                    receivableAmount:this.receivableAmount,
                    netReceiptsAmount:this.netReceiptsAmount,
                    netReceiptsTime:this.netReceiptsTime,
                    bossInputAmount:this.bossInputAmount,
                    adminId:this.adminId,
                },
            };
            orderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                    this.status = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 107px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            display: inline-block;
            width: 90px;
        }
    }
}
.footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
        background-color: #2370eb;
        border-color: #2370eb;
    }
    button:last-child {
        margin-right: 30px;
    }
}
</style>
